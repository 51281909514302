/* eslint-disable react/prop-types */
import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';
import { Card, PolycardContext } from '@polycard/card';
import { Icons } from '@polycard/icons';

import { APP_PREFIX, DEVICE_TYPE } from '@constants/commons';
import { REVIEWS } from '@constants/components-ids';
import { CARD_TYPE } from '@constants/recommendations';
import { arrayFind } from '@utils/array';

const namespace = `${APP_PREFIX}-card-featured`;

const PolyCardTemplate = ({ cardNumber, ...cardProps }) => (
  <Card polycard={cardProps} cardNumber={cardNumber} as="li" />
);

const CardFeatured = (props) => {
  const {
    recommendation_data: { recommendation_info: recommendationInfo },
    deviceType,
    country,
  } = props;

  if (!recommendationInfo) {
    return null;
  }

  const recommendations = recommendationInfo?.recommendations;
  const cardData =
    recommendations && recommendations.length > 0
      ? recommendations[0]
      : recommendationInfo;

  const { polycard_context: polycardContext, polycards } = cardData;
  const polycardsLength = polycards?.length;
  const polycardCard = polycardsLength > 0 && polycards && polycards[0];

  const reviews = arrayFind(
    recommendationInfo.polycards[0].components,
    REVIEWS,
  );

  return (
    <section
      className={classnames(
        namespace,
        'ui-recommendations-list__items-wrapper--default',
      )}
    >
      <PolycardContext
        contextValue={{
          ...polycardContext,
          type:
            deviceType === DEVICE_TYPE.MOBILE
              ? CARD_TYPE.LIST
              : 'list-multicolumn',
        }}
        deviceType={
          deviceType === DEVICE_TYPE.MOBILE
            ? DEVICE_TYPE.MOBILE
            : DEVICE_TYPE.DESKTOP
        }
        countPreloadImages={polycardsLength}
        thousandSeparator={country?.thousands_separator}
        decimalSeparator={country?.decimal_separator}
      >
        <PolyCardTemplate {...polycardCard} />
        {reviews && (
          <Icons icons={['poly_star_half', 'poly_star_fill', 'poly_star']} />
        )}
      </PolycardContext>
    </section>
  );
};

CardFeatured.propTypes = {
  namespace: string,
};

export default CardFeatured;
